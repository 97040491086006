import React from 'react';
import { css } from '@emotion/react';
import { navigate } from 'gatsby';
import { Container, Box, Typography, Card, CardActionArea, CardHeader, CardMedia } from '@mui/material';

const containerCSS = css`
display: flex; 
align-items: center; 
justify-content: space-evenly;
flex-wrap: wrap-reverse;
border-bottom: 1px solid;
padding-bottom: 16px;
margin-bottom: 16px;
`;
const boxCss = css`
    max-width: 720px;
`;
const spriteCss = css`
    height: 250px;
    margin-bottom: 24px;
`;
const imageListCss = css`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
`;

const IndexPage = () => {
    return (
        <>
            <Container disableGutters css={containerCSS}>
                <Box css={boxCss}>
                    <Typography gutterBottom component="h1" variant="h3" align="center">
                        Development
                    </Typography>
                    <Typography paragraph component="p" variant="h6">
                        Playground of development work
                    </Typography>
                </Box>
            </Container>
            <Container disableGutters css={imageListCss}>
                <Card>
                    <CardActionArea onClick={() => {navigate('/development/pokemon')}}>
                        <CardHeader title="PokeAPI" subheader="RESTful Web Service Integration" />
                        <CardMedia css={spriteCss} image="https://avatars.githubusercontent.com/u/64151210?v=4" alt="PokeAPI" />
                    </CardActionArea>
                </Card>
                <Card>
                    <CardActionArea onClick={() => { navigate('/development/spacex') }}>
                        <CardHeader title="SpaceX Launches" subheader="Apollo/GraphQL Integration" />
                        <CardMedia css={spriteCss} image="https://www.spacex.com/static/images/share.jpg" alt="Space X Logo" />
                    </CardActionArea>
                </Card>
                <Card>
                    <CardHeader title="ArcGIS" subheader="coming soon" />
                    <CardMedia css={spriteCss} image="https://www.gartner.com/imagesrv/peer-insights/vendors/logos/esri.jpg" alt="Esri ArcGIS" />

                </Card>
                <Card>
                    <CardHeader title="More to come" subheader="Always playing with new things" />
                </Card>
            </Container>
        </>
    )
}

export default IndexPage;